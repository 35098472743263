import { openPopupWithReturn } from "../OpenPopupWithReturn";

export const openExternalLoginPopup = function (url: string) {
  openPopupWithReturn({
    name: "ExternalLoginPopup",
    width: 600,
    height: 800,
    url,
  });
};
