import { Elm } from "./Main.elm";

import * as NriProgram from "../../../lib/nri-elm-shared--src/Nri/ProgramTS";
import * as Browser from "../../Browser";
import { openExternalLoginPopup } from "../../../lib/nri-elm-shared--src/Nri/ExternalLoginPopup";

NriProgram.domready(function () {
  // If the user can see the login form, they are signed out.
  // Clear user data from analytics.
  if (window.analytics) {
    window.analytics.reset();
  }

  const json = NriProgram.flags("manual-login-elm-flags") as Record<
    string,
    unknown
  >;

  const flags = {
    isPopOut: false,
    isGoogleNotFound: json["google_not_found"],
    allowCleverLogin: json["allow_clever_login"],
    allowClassLinkLogin: json["allow_classlink_login"],
    allowGoogleLogin: json["allow_google_login"],
    startExpanded: true,
    username: json["username"],
    cleverUrl: json["clever_url"],
    classLinkUrl: json["class_link_oauth_url"],
    googleSSOEnabled: json["google_enabled"],
    classLinkEnabled: json["class_link_enabled"],
    csrfToken: json["csrf_token"],
    cookieEnabled: navigator.cookieEnabled,
    isSafari: Browser.isSafari,
    redirectTo: json["redirect_to"],
  };

  // Our site warns users if their cookies are disabled and asks them to enable them because our
  // auth infrastructure requires them. The cookie warning has to replace the entire login/signup
  // layout, but the normal login Elm app is mounted inside of the login container. In order to
  // allow the cookie warning to take over the space we take the elm mount out of the container, put
  // it back in the DOM on the outside of the form layout, and then hide the form layout.
  if (!navigator.cookieEnabled) {
    const manualLoginElmHost = document.getElementById("manual-login-elm-host");
    const newSessionBody =
      document.querySelector<HTMLElement>(".new-session-body");
    const newSessionContainer = document.querySelector(
      ".new-session-container",
    );

    if (!manualLoginElmHost || !newSessionBody || !newSessionContainer) {
      throw "could not find the elements needed to display the cookie warning";
    }

    newSessionContainer.insertBefore(manualLoginElmHost, newSessionBody);
    newSessionBody.style.display = "none";
  }

  const app = NriProgram.mountWithFlags(
    Elm.Page.Login.Main,
    "manual-login-elm-host",
    flags,
    "Page/Login/index.ts",
  );

  // Note: calling this port after reporting.bindPorts causes some side effect
  // that breaks the external window popup script for the "Log in with Google"
  // button. Removed it so now this behaves the same way as the Signup page.
  app.ports.openExternalLoginPopup.subscribe(function (url) {
    openExternalLoginPopup(url);
  });
});
