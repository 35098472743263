import { openPopup } from "../../popup";

export type Config = {
  name: string;
  width: number;
  height: number;
  url?: string;
};

declare global {
  interface Window {
    receiveMessageFromExternalLoginPopup(this: void, ev: MessageEvent): void;
  }
}

export const openPopupWithReturn = function ({
  name,
  width,
  height,
  url,
}: Config) {
  window.removeEventListener(
    "message",
    window.receiveMessageFromExternalLoginPopup,
  );

  openPopup(url, name, { width, height });

  window.addEventListener(
    "message",
    function (event) {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore-next-line
        if (event.source.name == name) {
          window.location.href = (
            event.data as { redirectUri: string }
          ).redirectUri;
        }
      } catch (err) {
        // This happens when we get a cross-origin event
        return;
      }
    },
    false,
  );

  return true;
};
