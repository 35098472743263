import { App, SendPort, SubscribePort } from "./ElmApp";

interface NavigationLocation {
  href: string;
  host: string;
  hostname: string;
  protocol: string;
  origin: string;
  port_: string;
  pathname: string;
  search: string;
  hash: string;
  username: "";
  password: "";
}

interface PushUrlCommand {
  type: "PushUrl";
  url: string;
}

interface ReplaceUrlCommand {
  type: "ReplaceUrl";
  url: string;
}

interface GoCommand {
  type: "Go";
  howMuch: number;
}

interface LoadCommand {
  type: "Load";
  url: string;
}

interface ReloadCommand {
  type: "Reload";
}

type NavigationCommand =
  | PushUrlCommand
  | ReplaceUrlCommand
  | GoCommand
  | LoadCommand
  | ReloadCommand;

const getLocation = (location: Location): NavigationLocation => ({
  href: location.href,
  host: location.host,
  hostname: location.hostname,
  protocol: location.protocol,
  origin: location.origin,
  port_: location.port,
  pathname: location.pathname,
  search: location.search,
  hash: location.hash,
  username: "",
  password: "",
});

export interface Ports {
  navigationUrlChanges: SendPort<NavigationLocation>;
  navigationCommands: SubscribePort<NavigationCommand>;
}

const setup = function (app: App<Partial<Ports>>) {
  if (!app.ports) {
    return;
  }

  const navigationUrlChanges = app.ports.navigationUrlChanges;
  if (navigationUrlChanges) {
    window.addEventListener("popstate", () =>
      navigationUrlChanges.send(getLocation(document.location)),
    );

    if (window.navigator.userAgent.indexOf("Trident") !== -1) {
      window.addEventListener("hashchange", () =>
        navigationUrlChanges.send(getLocation(document.location)),
      );
    }
  }

  if (app.ports.navigationCommands) {
    app.ports.navigationCommands.subscribe(function (data: NavigationCommand) {
      switch (data.type) {
        case "PushUrl":
          window.history.pushState({}, "", data.url);

          // Hi you might wonder why I am here. I am important for the custom
          // element for render time tracking.  These marks will otherwise do
          // nothing. But for that render time tracker which is currently setup
          // in the assignment library I mark the start point, and the
          // measurments that are taken look for me so please don't remove me.
          performance.mark("navigation-push-state", {
            detail: { url: data.url },
          });

          if (app.ports.navigationUrlChanges != null) {
            app.ports.navigationUrlChanges.send(getLocation(document.location));
          }
          break;

        case "ReplaceUrl":
          window.history.replaceState({}, "", data.url);
          if (app.ports.navigationUrlChanges != null) {
            app.ports.navigationUrlChanges.send(getLocation(document.location));
          }
          break;

        case "Go":
          if (data.howMuch !== 0) {
            window.history.go(data.howMuch);
          }
          break;

        case "Load":
          try {
            window.location.href = data.url;
          } catch (error) {
            document.location.reload();
          }
          break;

        case "Reload":
          document.location.reload();
          break;
      }
    });
  }
};

export { setup, getLocation, NavigationLocation };
