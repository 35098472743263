export type Options = {
  width: number;
  height: number;
};

export function openPopup(
  url: string | URL | undefined,
  name: string,
  options: Options,
): Window | null {
  // This logic here is to center the popup relative to the browser's window
  const windowLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const windowTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const windowWidth = window.outerWidth
    ? window.outerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
  const windowHeight = window.outerHeight
    ? window.outerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

  const left = windowLeft + (windowWidth - options.width) / 2;
  const top = windowTop + (windowHeight - options.height) / 2;

  return window.open(
    url,
    name,
    `toolbar=0,statusbar=0,menubar=0,width=${options.width},height=${options.height},left=${left},top=${top}`,
  );
}
