import type { SegmentAnalytics } from "@segment/analytics.js-core";
import { App, SendPort, SubscribePort } from "./ElmApp";

declare global {
  // nullable since adblockers sometimes block this code from loading
  interface Window {
    analytics?: SegmentAnalytics.AnalyticsJS;
    ga4DataLayer?: IArguments[];
  }
}

interface TrackingMessage<Property> {
  event: string;
  properties: Record<string, Property>;
}

export type Ports<Callback, Property> = {
  analyticsTrackOut: SubscribePort<TrackingMessage<Property>>;
  trackAndLoad: SubscribePort<{
    url: string;
    trackingMessage: TrackingMessage<Property>;
  }>;
  trackManyAndLoad: SubscribePort<{
    url: string;
    trackingMessages: TrackingMessage<Property>[];
  }>;
  trackAndOpen: SubscribePort<{
    url: string;
    trackingMessage: TrackingMessage<Property>;
  }>;
  trackAndReload: SubscribePort<{
    trackingMessage: TrackingMessage<Property>;
  }>;
  trackAndCallback: SubscribePort<{
    trackingMessage: TrackingMessage<Property>;
    callback: Callback;
  }>;
  trackingCallback: SendPort<Callback>;
};

const ANALYTICS_INITIALIZED = Symbol("ANALYTICS_INITIALIZED");

type AnalyticsApp<Callback, Property> = App<
  Partial<Ports<Callback, Property>>
> & { [ANALYTICS_INITIALIZED]?: boolean };

type AnalyticsProperty = string | number | boolean | null | undefined;

function start<Callback, Property>(app: AnalyticsApp<Callback, Property>) {
  const DEBUG = window.location.hostname === "localhost";

  if (app == null) {
    throw new Error(
      "Analytics.start expects an Elm app instance as an argument",
    );
  }

  if (app[ANALYTICS_INITIALIZED]) {
    console.warn("Analytics.start should only be called once per app");
    return;
  }
  app[ANALYTICS_INITIALIZED] = true;

  // app.ports will be null if there are no other ports defined by the Elm program
  if (
    !app.ports ||
    (app.ports.analyticsTrackOut == null && app.ports.trackAndLoad == null)
  ) {
    console.warn(
      "An Elm app is set up to use the Analytics port module, but it is not using any tracking Cmds",
    );
    return;
  }

  addFormSubmitEventListener();

  /**
   * Listen for submits on forms that have a `data-nri-track-form-submit` attribute,
   * intercepts the submit, sends the tracking event, and then re-submits the form.
   *
   * This is used by Nri.CSRF to track form submissions.
   */
  function addFormSubmitEventListener() {
    document.addEventListener("submit", (event) => {
      const form = event.target;

      if (!(form instanceof HTMLFormElement)) return;
      if (form.dataset["nriIsSubmitting"] === "true") return;

      const trackingMessageJSON = form.dataset["nriTrackFormSubmit"];

      if (typeof trackingMessageJSON !== "string") return;

      event.preventDefault();

      const trackingMessage = JSON.parse(
        trackingMessageJSON,
      ) as TrackingMessage<Property>;

      log("Track form submission:", trackingMessage);

      track(trackingMessage)
        .catch((err) => {
          console.error("Error tracking form submission:", err);
        })
        .finally(() => {
          form.dataset["nriIsSubmitting"] = "true";
          form.submit();
        });
    });
  }

  function withCommonProperties(
    properties: Record<string, Property | AnalyticsProperty>,
  ) {
    return { ...window.NRI_ENV.analyticsCommonProperties, ...properties };
  }

  function log(
    label: string,
    trackingInfo: TrackingMessage<Property | AnalyticsProperty>,
    err?: Error,
  ) {
    if (DEBUG) {
      console.info(label, trackingInfo.event, trackingInfo.properties, err);
    }
  }

  async function track(
    trackingMessage: TrackingMessage<Property | AnalyticsProperty>,
  ) {
    return new Promise<void>((resolve, reject) => {
      // Only try to do analytics if it's defined. Some users will have ad blockers.
      if (window.analytics) {
        // track documentation:
        // https://segment.com/docs/sources/website/analytics.js/
        try {
          window.analytics.track(
            trackingMessage.event,
            withCommonProperties(trackingMessage.properties),
            {},
            () => resolve(),
          );
        } catch (err) {
          if (err instanceof Error) reject(err);
          else if (typeof err === "string") reject(new Error(err));
          else reject(new Error(`Unexpected error in window.analytics.track`));
        }
      } else {
        reject(new Error("window.analytics is not defined"));
      }
    }).catch((err: Error) => {
      log("Error tracking:", trackingMessage, err);
      throw err;
    });
  }

  if (app.ports.analyticsTrackOut != null) {
    app.ports.analyticsTrackOut.subscribe(function (trackingInfo) {
      // Analytics debugging isn't always reliable, so to make it so we'll also log out the tracking info.
      log("Track:", trackingInfo);

      void track(trackingInfo);
    });
  }

  if (app.ports.trackManyAndLoad != null) {
    app.ports.trackManyAndLoad.subscribe(function (info) {
      if (DEBUG) {
        console.group("Track many before page change");
      }

      const promises = info.trackingMessages.map(async (trackingMessage) => {
        log("Track:", trackingMessage);
        await track(trackingMessage);
      });

      void Promise.all(promises).finally(() => {
        if (DEBUG) {
          console.groupEnd();
        }
        window.location.href = info.url;
      });
    });
  }

  if (app.ports.trackAndLoad != null) {
    app.ports.trackAndLoad.subscribe(({ trackingMessage, url }) => {
      log("Track before page change:", trackingMessage);

      // Use .finally() instead of .then() so that the page will still load even if the tracking fails.
      track(trackingMessage).finally(() => (window.location.href = url));
    });
  }

  if (app.ports.trackAndReload != null) {
    app.ports.trackAndReload.subscribe(({ trackingMessage }) => {
      log("Track before page reload:", trackingMessage);

      // Use .finally() instead of .then() so that the page will still load even if the tracking fails.
      track(trackingMessage).finally(() => window.location.reload());
    });
  }

  if (app.ports.trackAndOpen != null) {
    app.ports.trackAndOpen.subscribe(({ trackingMessage, url }) => {
      log("Track before page open:", trackingMessage);

      // Use .finally() instead of .then() so that the page will still load even if the tracking fails.
      track(trackingMessage).finally(() => window.open(url, "_blank"));
    });
  }

  if (
    app.ports.trackAndCallback != null &&
    app.ports.trackingCallback != null
  ) {
    app.ports.trackAndCallback.subscribe(function ({
      trackingMessage,
      callback,
    }) {
      log("Track before callback:", trackingMessage);

      // Use .finally() instead of .then() so that the page will still load even if the tracking fails.
      track(trackingMessage).finally(() =>
        app.ports.trackingCallback?.send?.(callback),
      );
    });
  }

  /* When a user signs out of NRI, we also need to clear their personal data from analytics.
   *  All logouts occur via a link, so in order to clear the data, we add event listeners
   *  to all logoout links.
   */
  if (window.analytics) {
    document.querySelectorAll("a[href='/logout']").forEach((logoutButton) => {
      logoutButton.addEventListener("click", function () {
        window.analytics && window.analytics.reset();
      });
    });
  }
}

export { start };
